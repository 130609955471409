import { AppBar, Toolbar, Box, Avatar, styled, MenuItem, Menu } from '@mui/material';
import styles from './Navbar.module.css';
import { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext';
import { logoutUser } from '../../api/authApi';
import { Link, useNavigate } from 'react-router-dom';
import Snackbar from "@mui/material/Snackbar";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  top: 0,
  width: '100%',
  zIndex: 1000,
  margin: 0,
  padding: 0,
  boxSizing: 'border-box',
  boxShadow: 'none',
}));

const Navbar = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logoutUser();
    setTimeout(() => {
      setUser(null);
      navigate("/");
    }, 2000); // why do we do this?
    handleClickAlert();

  };

  const handleViewAcc = () => {
    navigate('../../viewAccount');
  };

  const handleAdminPage = () => {
    navigate('../../admin-stats');
  }
  const handleBugReports = () => {
    navigate('../../bug-reports');
  }


  const handleClickAlert = () => {
    setState({ ...state, open: true });
  };

  const handleCloseAlert = () => {
    setState({ ...state, open: false });
  };


  return (
    <StyledAppBar className={styles.navbar} sx={{ backgroundColor: '#89b0ae' }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleCloseAlert}
        message="You have been logged out"
        key={vertical + horizontal}
      />
      <Toolbar style={{ height: '50px', justifyContent: 'space-between' }}>
        <Link to="/">
          <img src="paws-4-your-home-logo.png" alt="Paws 4 Your Home Logo" className={styles.logo} />
        </Link>

        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Link to="/create-listing" className={styles.links}>Create Listing</Link>
          <Link to="/viewAccount" className={styles.links}>View Account</Link>
          <Link to="/messages" className={styles.links}>Messages</Link>
          {(user != null && user.accountType == 0) && <Link to="/admin-stats" className={styles.links}>Admin Stats</Link>}
          {(user != null && user.accountType == 0) && <Link to="/bug-reports" className={styles.links}>Bug Reports</Link>}
        </Box>

        {user ? (
          <> 
            <Avatar
              alt={user?.username || 'User'}
              src={user?.profilePicture || ''}
              className={styles.avatar}
              onClick={handleClick}
            />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={handleViewAcc}>My Account</MenuItem>
              {(user != null && user.accountType == 0) && <MenuItem onClick={handleAdminPage}>Admin Stats</MenuItem>}
              {(user != null && user.accountType == 0) && <MenuItem onClick={handleBugReports}>Bug Reports</MenuItem>}
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Avatar
              alt="Anonymous"
              src=""
              className={styles.avatar}
              onClick={handleClick}
              sx={{
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                }
              }}
            />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={() => navigate('/login')}>Login</MenuItem>
              <MenuItem onClick={() => navigate('/createaccount')}>Sign Up</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
