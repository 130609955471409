import foster from "./FosterHomepage.module.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {getAllFosterListings, getAllActiveFosterListings} from "../../api/adoptFosterApi";
import { useUser } from "../../context/UserContext";
import {GeocodePlaceId} from "../../component/GoogleMaps/GoogleMapsDisplay";

import {refreshToken} from "../../api/authApi";
import { useNavigate } from "react-router-dom"
import {getAccountDetails} from "../../api/userApi";

export default function FosterHomepage() {
    const [fosterListings, setFosterListings] = useState("");
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const [imagePreview, setImagePreview] = useState(null);

    const [userData, setUserData] = useState("");

    const Pets = (props) => (
        <div>
            <div className={foster.overallPetPost}>
                <Link className={foster.petPost} to={`/view-foster-listing/${props.pet.listingID}`}>
                    {props.pet.picture[0] ? (
                        <img
                            src={props.pet.picture[0]}
                            className={foster.petPicture}
                            alt="Pet Image"
                        />
                    ) : (
                        <img
                            src= "/Image_not_available.png"
                            alt="Image Not Available"
                            className={foster.petPicture}
                        />
                    )}
                    <div className={foster.nameAndFeeTextContainer}>
                        <div className={foster.signintext}>
                            {props.pet.name}
                        </div>
                        <div className={foster.gridColoring}>
                            {props.pet.fee !== null ? 
                                <div className={foster.feeText}>
                                    Fee: ${props.pet.fee}
                                </div> : 
                                <div></div>}
                        </div>
                    </div>
                    <div className={foster.locationText}>
                        {props.pet.pickupLocationID && props.pet.pickupLocationID !== "" 
                            && props.pet.pickupLocationID !== "N/A"
                                        ? <GeocodePlaceId placeId={props.pet.pickupLocationID} />
                                        : "Message for location"}
                    </div>
                </Link>
            </div>
        </div>
    );

    function fosterOrNot() {
        if (fosterListings.length === 0) {
            return (
                <div className={foster.noListingsText}>
                    No Foster Listings
                </div>
            );
        }
        else {
            return (
                fosterList()
            );
        }
    }

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {
                const userDetails = await getAccountDetails(res.user.userID);
                setUserData(userDetails);
                setImagePreview(userDetails.profilePicture);
                const petDetails = await getAllActiveFosterListings();
                console.log(petDetails);
                setFosterListings(petDetails);
            }
        };

        checkToken();
    }, [setUser, navigate, setFosterListings]);


    function fosterList() {
        return fosterListings.map((pet) => {
            return (
                <Pets
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }

    return (
        <div className={foster.page}> 
            <div className={foster.listingsContainer}>
                <div className={foster.welcomebacktext}>
                    Pets up for Fostering
                </div>
                <div className={foster.containerForFilters}></div>

                <div className={foster.petListings}>
                    <ul className={foster.listOfPets}>
                        {fosterOrNot()}
                    </ul>
                </div>
            </div>
        </div>
    );
}