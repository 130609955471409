import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton, InputLabel, Select, MenuItem, FormControl,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { updateListingByID, getListingByID, deleteListingByID } from "../../api/listingApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useUser } from "../../context/UserContext";
import { GooglePlacesAutocompleteEdit } from "../../component/GoogleMaps/GooglePlacesAutocomplete";
import updateListingStyles from "./UpdateListing.module.css";
import { Delete, Restore } from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { refreshToken } from "../../api/authApi";


export default function UpdateListingPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    gender: "",
    type: "",
    description: "",
    dob: dayjs(),
    fee: "",
    weight: "",
    breed: "",
    locationID: "",
    specialNeeds: "",
    pickupDate: dayjs(),
    lastSeenDate: dayjs(),
    chipNumber: "",
    foundDate: dayjs(),
    // pickupDate: dayjs(),
    imagesDeleted: []
  });
  const types = [
    'Cat',
    'Dog',
    'Hamster',
    'Ferret',
    'Bird',
    'Reptile',
    'Amphibian',
    'Rabbit',
    'Guinea Pig',
    'Fish',
    'Pet Livestock',
    'Pet Poultry',
  ];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const fileInputRef = useRef(null);
  const today = dayjs();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        try {
          console.log("Fetching token and user...");
          const res = await refreshToken();
          if (res.user) {
            await setUser(res.user);
          } else {
            console.log("User context could not be refreshed, redirecting to login.");
            navigate("../../login");
            return;
          }
        } catch (error) {
          console.error("Error refreshing token:", error);
          navigate("../../login");
          return;
        }
      }
      console.log("User context refreshed.");
      if (user) {
        try {
          console.log("Fetching listing data...");
          const res = await getListingByID(id);
          const listing = res.listing;

          if (listing.userID !== user.userID) {
            setSnackbarMessage("You do not have permission to edit this listing.");
            setOpenSnackbar(true);
          }

          // convert to locationID
          if (listing.pickupLocationID) {
            listing.locationID = listing.pickupLocationID;
          } else if (listing.lastSeenLocationID) {
            listing.locationID = listing.lastSeenLocationID;
          } else if (listing.currentLocationID) {
            listing.locationID = listing.currentLocationID;
          } else if (listing.foundLocationID) {
            listing.locationID = listing.foundLocationID;
          }

          setFormData((prevFormData) => ({
            ...prevFormData,
            ...listing,
            dob: dayjs(listing.dob),
            pickupDate: dayjs(listing.pickupDate),
            lastSeenDate: dayjs(listing.lastSeenDate),
            foundDate: dayjs(listing.foundDate),
            // pickupDate: dayjs(listing.pickupDate),
            imagesDeleted: prevFormData.imagesDeleted || [],
          }));

          setExistingImages(listing.picture || []);
        } catch (error) {
          console.error("Error fetching listing data:", error);
          setSnackbarMessage("Failed to load listing data.");
          setOpenSnackbar(true);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [id, user, navigate, setUser]);



  const handleFormSubmit = async () => {
    const updateData = {
      ...formData,
      dob: formData.dob?.toISOString(),
      pickupDate: formData.pickupDate?.toISOString(),
      lastSeenDate: formData.lastSeenDate?.toISOString(),
      imagesDeleted: formData.imagesDeleted,
    };

    // convert locationid to appropriate location
    console.log("locationID", formData.locationID);
    if (formData.locationID) {
      if (formData.listingType === "LostListing") {
        console.log("setting last seen location");
        updateData.lastSeenLocationID = formData.locationID;
      } else if (formData.listingType === "FoundListing") {
        console.log("setting found location");
        updateData.foundLocationID = formData.locationID;
      } else if (formData.listingType === "RehomeListing") {
        console.log("setting current location");
        updateData.currentLocationID = formData.locationID;
      } else if (formData.listingType === "AdoptFosterListing") {
        console.log("setting pickup location");
        updateData.pickupLocationID = formData.locationID;
      }
    }

    console.log("updating listing with data:", updateData);
    const result = await updateListingByID(updateData, id);
    if (result && result.status < 400) {
      setSnackbarMessage("Listing updated successfully.");
      setOpenSnackbar(true);
    }
    else {
      setSnackbarMessage("Error updating listing.");
      setOpenSnackbar(true);
    }
  };

  const handleFormSubmitStatus = async () => {
    const updateData = {
      ...formData,
      dob: formData.dob?.toISOString(),
      pickupDate: formData.pickupDate?.toISOString(),
      lastSeenDate: formData.lastSeenDate?.toISOString(),
      imagesDeleted: formData.imagesDeleted,
      status: 0
    };

    console.log("updating listing with data:", updateData);
    const result = await updateListingByID(updateData, id);
    if (result && result.status < 400) {
      setSnackbarMessage("Listing updated successfully.");
      setOpenSnackbar(true);
    }
    else {
      setSnackbarMessage("Error updating listing.");
      setOpenSnackbar(true);
    }
  };

  // DELETE LISTING
  const handleDeleteListing = async () => {
    const res = await deleteListingByID(id);
    setSnackbarMessage("Deleted Listing")
    setOpenSnackbar(true);
    navigate('/');
  };

  const toggleImageDeletion = (imageUrl) => {
    setFormData((prev) => {
      const updatedDeletedImages = prev.imagesDeleted.includes(imageUrl)
        ? prev.imagesDeleted.filter((img) => img !== imageUrl)
        : [...prev.imagesDeleted, imageUrl];
      return { ...prev, imagesDeleted: updatedDeletedImages };
    });
  };

  const handleConfirmAction = async () => {
    await handleFormSubmitStatus();
    setOpenConfirmDialog(false);
  };

  const handleChange = (field, value) => {
    console.log(field, value);
    setFormData((prev) => ({ ...prev, [field]: value }));
  }

  const getListingType = () => {
    if (formData.adoptOrFoster === 0) {
      return "Adopt";
    } else if (formData.adoptOrFoster === 1) {
      return "Foster";
    } else if (formData.listingType === "FoundListing") {
      return "Found";
    } else if (formData.listingType === "LostListing") {
      return "Lost";
    } else if (formData.listingType === "RehomeListing") {
      return "Rehome";
    } else {
      return "Unknown";
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={updateListingStyles.page}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        key={"topcenter"}
      />
      <div className={updateListingStyles.containerWithItems}>
        <div className={updateListingStyles.containerwithtext}>
          <h4>Update {getListingType()} Listing</h4>

          <div className={updateListingStyles.containerwithitemfill}>
            <TextField label="Name" fullWidth onChange={(e) => handleChange("name", e.target.value)} value={formData.name} />
            <TextField label="Age" fullWidth type="number" onChange={(e) => handleChange("age", Number(e.target.value))} value={formData.age} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="Birthday" maxDate={today} value={formData.dob} onChange={(date) => handleChange("dob", date)} />
            </LocalizationProvider>
            <TextField label="Gender" fullWidth onChange={(e) => handleChange("gender", e.target.value)} value={formData.gender} />
            <TextField label="Weight" fullWidth type="number" onChange={(e) => handleChange("weight", Number(e.target.value))} value={formData.weight} />
            <FormControl sx={{m: 0}}>
              <InputLabel id="demo-simple-select-autowidth-label">Type of Pet *</InputLabel>
              <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={formData.type}
                  onChange={(e) => handleChange("type", e.target.value)}
                  autoWidth
                  label="Type of Pet"
                  required={true}
              >
                {types.map((type) => (
                    <MenuItem
                        key={type}
                        value={type}
                    >
                      {type}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField label="Breed" fullWidth onChange={(e) => handleChange("breed", e.target.value)} value={formData.breed} />
            <TextField
              label="Description"
              fullWidth
              onChange={(e) => handleChange("description", e.target.value)}
              multiline
              rows={5}
              required
              value={formData.description}
            />
            {/* Additional Fields */}

            {getListingType() === 'Found' && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Found Date"
                  views={['year', 'month', 'day']}
                  value={formData.foundDate}
                  onChange={(date) => handleChange("foundDate", date)}

                />
              </LocalizationProvider>
            )}

            {['Found', 'Lost'].includes(getListingType()) && (
              <TextField label="Chip Number" fullWidth onChange={(e) => handleChange("chipNumber", e.target.value)} required={getListingType() === 'Lost'} value={formData.chipNumber} />
            )}

            {getListingType() === 'Rehome' && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Pickup Date"
                  views={['year', 'month', 'day']}
                  value={formData.pickupDate}
                  onChange={(date) => handleChange("pickupDate", date)}

                />
              </LocalizationProvider>
            )}


            <TextField
              fullWidth
              InputProps={{
                inputComponent: GooglePlacesAutocompleteEdit,
                inputProps: {
                  originalPlaceId: formData.locationID,
                  onPlaceSelected: (placeId) => handleChange("locationID", placeId)
                }
              }}
            />



            <div className={updateListingStyles.carouselContainer}>
              <Carousel showThumbs={false} showStatus={false} className={updateListingStyles.carousel}>
                {existingImages.map((imageUrl, index) => (
                  <div key={index} className={updateListingStyles.carouselImageContainer}>
                    <img
                      src={imageUrl}
                      alt={`Image ${index}`}
                      className={`${updateListingStyles.carouselImage} ${formData.imagesDeleted.includes(imageUrl) ? updateListingStyles.deletedImage : ''}`}
                    />
                    <IconButton
                      onClick={() => toggleImageDeletion(imageUrl)}
                      style={{ position: 'absolute', top: '10px', right: '10px', color: formData.imagesDeleted.includes(imageUrl) ? 'green' : 'red' }}
                    >
                      {formData.imagesDeleted.includes(imageUrl) ? <Restore /> : <Delete />}
                    </IconButton>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className={updateListingStyles.buttonSubmit}>
            <Button variant="contained" style={{ backgroundColor: "#555B6E" }} onClick={handleFormSubmit}>Update Listing</Button>
            <Button variant="contained" style={{ backgroundColor: "#89B0AE" }} onClick={() => setOpenDeleteDialog(true)}>
              Delete Listing
            </Button>
            <Button variant="contained" style={{ backgroundColor: "#555B6E" }} onClick={() => setOpenConfirmDialog(true)}>
              {formData.adoptOrFoster === 0 ? "Mark as Adopted" : "Mark as Resolved"}
            </Button>
          </div>
        </div>
      </div>

      {/* Confirm Dialog for Marking as Adopted or Resolved */}
      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Are you sure you want to mark this as {formData.adoptOrFoster === 0 || formData.adoptOrFoster === 1 ? "Adopted" : "Resolved"}?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmAction} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Dialog for Deleting Listing */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Are you sure you want to delete this listing?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteListing} autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
