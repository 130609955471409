import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import css from "./ViewLostFoundPost.module.css";
import {getLostListing} from "../../api/lostApi"
import {getFoundListing} from "../../api/foundApi"
import {getAccountDetails} from "../../api/userApi";
import { Card, CardMedia, Button } from '@mui/material';
import {GeocodePlaceId, GoogleMapsDisplay} from "../../component/GoogleMaps/GoogleMapsDisplay";
import {useUser} from "../../context/UserContext";

export default function ViewLostFoundPost() {
    const navigate = useNavigate();
    const [pet, setPet] = useState(null);
    const [arrayOfPetPic, setArrayOfPetPic] = useState(null);
    const {id} = useParams();
    const [number, setNumber] = useState(0);
    const [userName, setUserName] = useState(null);
    const { user, setUser } = useUser();
    const previous = "<";
    const next = ">";

    useEffect(() => {
        async function fetchData() {
            if (id != null){
                var response = await getLostListing(id);
                if(response.message === "Listing or User not found") {
                    response = await getFoundListing(id);
                }
                if (response.message !== "Listing or User not found") {
                    /*Updating the dates */
                    response.datePosted = Date(response.datePosted);
                    response.dateUpdated = Date(response.dateUpdated);
                    response.dob = new Date(response.dob).toLocaleDateString();
                    if (response.listingType === "LostListing"){
                        response.lastSeenDate = new Date(response.lastSeenDate).toLocaleDateString();
                    }
                    else {
                        response.foundDate = new Date(response.foundDate).toLocaleDateString();
                    }

                    /*Ensuring everything is set at N/A if blank */
                    for (const value in response) {
                        if (response[value] === "") {
                            response[value] = "N/A";
                        }
                    }
                    /*Set everything into response */
                    setPet(response);
                    setArrayOfPetPic(response.picture);
                    const accountDetail = await getAccountDetails(response.userID);
                    if (accountDetail != null){
                        setUserName(accountDetail.name);
            
                    }

                }
            }
        }

        fetchData();

        return;
    }, [id, navigate]);

    if (!pet || !pet.name) {
        return  <div className={css.pageNotFound}>Page Not Found</div>;
    }

    const leftClick = async () => {
        if (arrayOfPetPic != null)
        {
            if (number - 1 < 0) {
                setNumber(arrayOfPetPic.length - 1);
            }
            else{
                setNumber(number-1);
            }
        }
    };

    const rightClick = async () => {
        if (arrayOfPetPic != null) {
            if (number  + 1 >= arrayOfPetPic.length) {
                setNumber(0);
            }
            else{
                setNumber(number + 1);
            }
        }

    };

    const handleEditListing = () => {
      navigate(`/update-listing/${id}`);
    }


    const {
        description,
        gender,
        name,
        type,
        breed,
        age,
        dob,
        weight,
        specialNeeds,
        lastSeenLocationID,
        lastSeenDate,
        foundDate,
        foundLocationID,
        chipNumber,
        datePosted,
        dateUpdated,
        listingType
    } = pet;
   
    return (
      <div className={css.page}>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
        </style>
        <div className={css.containerForInformation}>
            <div className={css.twocolumncontainer}>
                <div>
                    <div className={css.imageBox}>
                        {(arrayOfPetPic != null && arrayOfPetPic.length > 1) &&
                        <Card sx={{ maxWidth: '100%', maxHeight: '100%'}}>
                        <CardMedia
                            component="img"
                            image={arrayOfPetPic[number]}
                            alt={`${name}`}/>
                        </Card>}
                        {(arrayOfPetPic != null && arrayOfPetPic.length == 1) &&
                        <Card sx={{ maxWidth: '85%', maxHeight: '85%'}}>
                        <CardMedia
                            component="img"
                            image={arrayOfPetPic[number]}
                            alt={`${name}`}/>
                        </Card>}
                        {(arrayOfPetPic == null || arrayOfPetPic.length == 0) &&
                        <Card sx={{ maxWidth: '85%', maxHeight: '85%'}}>
                        <CardMedia
                            component="img"
                            src= "/Image_not_available.png"
                            alt={`Image Not Available`}/>
                        </Card>}
                    </div> 
                    {(arrayOfPetPic != null && arrayOfPetPic.length > 1) &&
                        <div className={css.buttonAlign}>
                        <Button style={{ backgroundColor: "#bee3db" }} color="#555B6E" size="large" onClick={leftClick} >
                            {previous}
                        </Button>
                        {number+1} / {arrayOfPetPic.length}
                        <Button style={{ backgroundColor: "#bee3db" }} color="#555B6E" size="large" onClick={rightClick}>
                            {next}
                        </Button>
                    </div>
                    }
                    <div className={css.editListingButton}>
                        {pet.userID === user.userID ?
                            <Button variant="contained"
                                    sx={{
                                        backgroundColor: '#89B0AE'
                                    }}
                                    onClick={handleEditListing}
                            >
                                Edit Pet Information
                            </Button>
                            : ""}
                    </div>
                </div>
                <div className={css.containerForText}>
                    <div className={css.description}>
                    {pet.name}
                    </div>
                    <div className={css.descriptionBox}>
                        <div className={css.descriptionInformation}>
                            Chip ID: {pet.chipNumber}
                        </div>  
                        <div className={css.descriptionInformation}>
                            Description: {pet.description}
                        </div>  
                        <div className={css.descriptionInformation}>
                            Type: {pet.type}
                        </div>   
                        <div className={css.descriptionInformation}>
                            Breed: {pet.breed}
                        </div>   
                        <div className={css.descriptionInformation}>
                            Gender: {pet.gender}
                        </div>   
                        <div className={css.descriptionInformation}>
                            Date of Birth: {pet.dob}
                        </div>   
                        <div className={css.descriptionInformation}>
                            Age: {pet.age} years old
                        </div>   
                        <div className={css.descriptionInformation}>
                            Weight: {pet.weight} lb
                        </div> 
                        <div className={css.descriptionInformation}>
                            Special Needs: {pet.specialNeeds}
                        </div> 
                        {(pet.listingType === "LostListing") &&
                        <div className={css.descriptionInformation}>
                              Status: Lost
                        </div>}
                        {(pet.listingType === "FoundListing") &&
                        <div className={css.descriptionInformation}>
                              Status: Found
                        </div>}
                        {(pet.listingType === "LostListing")  &&
                        <div className={css.descriptionInformation}>
                            Last Seen: {pet.lastSeenDate}
                        </div>}
                        {(pet.listingType === "FoundListing")  &&
                        <div className={css.descriptionInformation}>
                            Found On: {pet.foundDate}
                        </div>}
                        {/*Google Maps*/}
                        {(pet.listingType === "LostListing") && (<GeocodePlaceId placeId={pet.lastSeenLocationID} />)}
                        {(pet.listingType === "LostListing") && (<GoogleMapsDisplay placeID={pet.lastSeenLocationID} />)}

                        {(pet.listingType === "FoundListing") && (<GeocodePlaceId placeId={pet.foundLocationID} />)}
                        {(pet.listingType === "FoundListing") && (<GoogleMapsDisplay placeID={pet.foundLocationID} />)}

                    </div>
                  
                </div>
            </div>
            <div className={css.formatOfDates}>
                {(userName != null ) && <div className={css.dateUpdated}>
                    Posted By: {userName}
                </div>}
                <div className={css.dateUpdated}>
                    Posted On: {pet.datePosted}
                </div>
                <div className={css.dateUpdated}>
                    Last Updated: {pet.dateUpdated} 
                </div>
            </div>
            <div className={css.footer}>
            </div>
        </div>
      </div>
    );
}