import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faTwitter, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.routes}>
        <Link to="/reportbugs">Report Bugs</Link>
      </div>
      <div className={styles.socials}>
        <a href="https://www.youtube.com/watch?v=GJDNkVDGM_s" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </a>

      </div>
    </footer>
  );
};

export default Footer;
