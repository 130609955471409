import foundPets from "./FoundHomepage.module.css";
import {getAllFoundListings, getAllActiveListings} from "../../api/foundApi";
import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import {refreshToken} from "../../api/authApi";
import {useUser} from "../../context/UserContext";
import {CardMedia, Button} from "@mui/material";
import {GeocodePlaceId} from "../../component/GoogleMaps/GoogleMapsDisplay";

export default function FoundHomepage() {
    const [petListings, setPetListings] = useState("");
    const [petImage, setPetImage] = useState(null);
    const navigate = useNavigate();
    const { user, setUser } = useUser();

    const Posts = (props, key) => (
        <div className={foundPets.post}>
            <Button class={foundPets.button} href={`/view-lost-found-post/${props.pet.listingID}`}>
            <div className={foundPets.postedby}>
                <div className={foundPets.userposter}>
                    {props.pet.username}
                </div>
                <div className={foundPets.dateposted}>
                    Posted On: {new Date(props.pet.datePosted).toLocaleDateString()}
                </div>
            </div>
            <div className={foundPets.post}>
                <div className={foundPets.postdescription}>  
                    <div className={foundPets.words}>
                         <div className={foundPets.name}>
                            {props.pet.name} -  {props.pet.gender} {props.pet.type}
                        </div>
                        <div className={foundPets.information}>
                            Last Seen on {new Date(props.pet.foundDate).toLocaleDateString()} 
                        </div>
                        <div className={foundPets.information}>
                            {props.pet.foundLocationID && props.pet.foundLocationID !== "" && props.pet.foundLocationID !== "N/A"
                                        ? <GeocodePlaceId placeId={props.pet.foundLocationID} />
                                        : "Message for location"}
                        </div>
                        <div className={foundPets.information}>
                            {props.pet.description}
                        </div>
                    </div>
                    <div className={foundPets.buttonPlacement}>
                        <Button variant="contained" style={{ backgroundColor: "#555B6E" }} size="large">
                            Found it! 
                        </Button>
                    </div>
                        
                </div>
                    <div className={foundPets.image}>
                    {(props.pet.picture.length !== 0) &&
                        <CardMedia
                            sx={{ maxWidth: '200px', maxHeight: "150px", minHeight:"150px"}}
                            component="img"
                            image={props.pet.picture[0]}
                            alt={`${props.pet.name}`}/>
                    }
                    {(props.pet.picture.length === 0) &&
                        <CardMedia
                            sx={{ maxWidth: '200px', maxHeight: '200px', minHeight:"150px"}}
                            component="img"
                            src= "Image_not_available.png"
                            alt={`Image Not Available`}/>
                    }
                    </div>
                </div>
            </Button>
        </div>
    );

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {

                const result = await getAllActiveListings();
                setPetListings(result);
            }
        };

        checkToken();
    }, [setUser, navigate, setPetListings]);


    function listOfPetsFound() {
        if (petListings.length === 0) {
            return (
                <div className={foundPets.page}>
                    No Found Pet Posting at the moment.
                </div>
            );
        }
        else {
            return (
                <div className={foundPets.listOfPets}>
                    {petList()}
                </div>
            );
        }
    }

    
    function petList() {
        return petListings.map((pet) => {
            return (
                <Posts
                    pet={pet}
                    key={pet._id}
                />
            );
        });
    }


    return (
        <div className={foundPets.page}>
            <div className={foundPets.containerWithItems}>
                <div className={foundPets.welcomebacktext}>
                    Found Pet Postings
                </div>
                <div className={foundPets.listOfPosts}>
                   {listOfPetsFound()}
                </div>
            </div>
        </div>
    );
}