import React, { useState, useEffect } from "react";
import css from "./AdminStats.module.css";
import { useUser } from '../../context/UserContext';

//TODO: GET THE STATS FOR THE SERVER

export default function AdminStats() {
    const [isAdmin, setIsAdmin] = useState(false);
    const stats = [];
    const { user, setUser } = useUser();

    useEffect(() => {
        console.log(user);
        if (user != null) {
            if (user.accountType == 0)
                {
                    setIsAdmin(true);
                }
                else{
                    setIsAdmin(false);
                }
        }
    }, [user]);

    return (
        <div className={css.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            {(!isAdmin) && 
                <div className={css.pageNotAvailable}>
                    This page is unavailable
                </div>}
            {(isAdmin) && 
                <div className={css.items}>
                    <div className={css.title}>
                            Admin Page
                    </div>
                    <div className={css.backgroundForItem}>
                        <div className={css.containerWithItem}>
                            <div className={css.text}>
                                Load:
                            </div>
                            <div className={css.text}>
                                Uptime:
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    );
} 