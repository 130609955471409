import rehome from "./RehomeHomepage.module.css";
import {Card, Grid} from "@mui/material";
import ComplexGrid from "./ComplexRehomeGrid";
import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import {refreshToken} from "../../api/authApi";
import {getAccountDetails} from "../../api/userApi";
import {useUser} from "../../context/UserContext";

import * as React from "react";
import viewAccount from "../ViewAccount/ViewAccount.module.css";
import {getAllRehomeListings, getAllActiveListings} from "../../api/rehomeApi";
import ComplexRehomeGrid from "./ComplexRehomeGrid";

export default function RehomeHomepage() {
    var script = document.createElement('script');
    script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js';
    document.getElementsByTagName('head')[0].appendChild(script);

    const { user, setUser } = useUser();
    const navigate = useNavigate();

    const [userData, setUserData] = useState("");
    const [imagePreview, setImagePreview] = useState(null);
    const [arrayOfPetPic, setArrayOfPetPic] = useState(null);
    const [petListings, setPetListings] = useState("");
    let counter = 1;

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {
                const userDetails = await getAccountDetails(res.user.userID);
                setUserData(userDetails);
                setImagePreview(userDetails.profilePicture);

                const petDetails = await getAllActiveListings();
                setPetListings(petDetails);
            }
        };

        checkToken();
    }, [setUser, navigate, setPetListings]);

    function rehomePets() {
        if (petListings.length === 0) {
            return (
                <div className={rehome.page}>
                    No pets currently up for adoption
                </div>
            );
        }
        else {
            return (
                <div className={rehome.listOfPets}>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                        {petList()}
                    </Grid>
                </div>
            );
        }
    }

    function petList() {
        return petListings.map((pet) => {
            return (
                <ComplexRehomeGrid
                    pet={pet}
                />
            );
        });
    }

    return (
        <div className={rehome.page}>
            <div className={viewAccount.signintext}>
                Pets up for Adoption
            </div>
            <div className={rehome.containerWithItems}>
                <div className={rehome.containerForFilters}></div>
                <div className={rehome.containerWithListings}>
                    {rehomePets()}
                </div>
            </div>
        </div>
    );
}