import React, { useState, useEffect } from "react";
import { TextField, Button, Alert } from "@mui/material";
import logincss from "./Login.module.css";
import { loginUser } from "../../api/authApi";
import { useUser } from '../../context/UserContext';
import { useNavigate } from "react-router-dom";


export default function LoginPage() {
    const { login } = useUser();
    const [identification, setIdentification] = useState("");
    const [password, setPassword] = useState("");
    const [dataUsed, setDataUsed] = useState(false);
    const [identificationUsed, setIdentificationUsed] = useState(false);
    const [passwordUsed, setPasswordUsed] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleLoginFormClick = async () => {
        //Verification - Red Error if field not entered correctly 
        if (identification === "") {
            setIdentificationUsed(true);
        }
        if (password === "") {
            setPasswordUsed(true);
        }

        // If all fields are entered correctly, proceed to login
        try {
            const userData = await loginUser(identification, password);
            if (typeof userData.user !== 'undefined') {
                await login(userData.user);
                console.log("Login successful", userData.user);
                if (userData.user && userData.user.userID && userData.user.status === 1) {
                    navigate("/needverification");
                } else {
                    navigate("/home");
                }
            }
            else {
                console.log("Login failed", userData.message)
                //Check which one failed, set that up as error message
                if (userData.message === "Invalid username or email") {
                    userData.message = userData.message + ". Please create an account if account does not exist."
                }
                setErrorMessage(userData.message);
            }


        } catch (err) {
            console.error(err);
        }

        console.log("Success");
    };
    const handleEmailChange = (event) => {
        setIdentification(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const navigateToCreate = async () => {
        navigate("../../createAccount")
    };

    const navigateToForgot = async () => {
        navigate("../../ResetPassword")
    };

    return (
        <div className={logincss.page}>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap" rel="stylesheet');
            </style>
            <div className={logincss.containerWithItems}>
                <div className={logincss.welcomebacktext}>
                    WELCOME BACK
                </div>
                <div className={logincss.containersignin}>
                    <div className={logincss.signintext}>SIGN IN</div>

                    <TextField label="Username or Email ID" variant="standard" size="medium" fullWidth onChange={(event) => handleEmailChange(event)}
                        disabled={dataUsed === true ? true : false} error={identificationUsed === true ? true : false} />


                    <TextField label="Password" type="password" variant="standard" size="medium" fullWidth onChange={(event) => handlePasswordChange(event)}
                        disabled={dataUsed === true ? true : false} error={passwordUsed === true ? true : false} />

                    <div className={logincss.alignButtonRights}>
                        <div>
                            <Button color="#555B6E" size="small" onClick={navigateToForgot}>Forgot Password?</Button>
                        </div>
                        <div>
                            <Button color="#555B6E" size="small" onClick={navigateToCreate}>Don't have an account?</Button>
                        </div>
                    </div>

                    <Button style={{ backgroundColor: "#555B6E" }} variant="contained" onClick={handleLoginFormClick}>LOGIN</Button>
                    {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                </div>

            </div>

        </div>
    );
} 