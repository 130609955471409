import css from "./AdminBugReport.module.css";
import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import {refreshToken} from "../../api/authApi";
import {useUser} from "../../context/UserContext";
import {CardMedia, Button} from "@mui/material";
import {getAllBugs} from "../../api/bugApi"


export default function AdminBugReport() {
    const [bugReport, setBugReport] = useState("");
    const navigate = useNavigate();
    const { user, setUser } = useUser();

    const Posts = (props, key) => (
        <div className={css.post}>
            <div className={css.postedby}>
                <div className={css.userposter}>
                    Submitted By: {props.bug.userID}
                </div>
                <div className={css.dateposted}>
                    Created On: {new Date(props.bug.createdAt).toLocaleDateString()}
                </div>
            </div>  
            <div className={css.post}>
                <div className={css.postdescription}>  
                    <div className={css.words}>
                        <div className={css.information}>
                            Bug Description: 
                        </div>
                        <div className={css.information}>
                              {props.bug.description}
                        </div>
                    </div>
                    <div className={css.buttonPlacement}>
                        <Button variant="contained" style={{ backgroundColor: "#555B6E" }} size="large">
                            Resolved
                        </Button>
                </div>     
                </div>    
            </div>
        </div>
    );

    useEffect(() => {
        const checkToken = async () => {
            const res = await refreshToken();
            setUser(res.user);
            if (res.user === undefined) {
                navigate("../../login");
            } else {

                const result = await getAllBugs();
                console.log(result);
                setBugReport(result);
            }
        };

        checkToken();
    }, [setUser, navigate, setBugReport]);


    function listOfBugReports() {
        if (bugReport != null) {
            if (bugReport.length === 0) {
                return (
                    <div className={css.page}>
                        No Bug Reports Existing
                    </div>
                );
            }
            else {
                return (
                    <div className={css.listOfPets}>
                        {BugList()}
                    </div>
                );
            }
        }
        else{
            return <div className={css.pageNotAvailable}>  This page is unavailable </div>
        }
       
    }

    
    function BugList() {
        return bugReport.map((bug) => {
            console.log(bug)
            return (
                <Posts
                    bug={bug}
                    key={bug._id}
                />
            );
        });
    }


    return (
        <div className={css.page}>
            <div className={css.containerWithItems}>
                {(user!= null && user.accountType == 0) && <div className={css.welcomebacktext}>
                    Bug Reports
                </div> }
                <div className={css.listOfPosts}>
                   {listOfBugReports()}
                </div>
            </div>
        </div>
    );
}